<template>
  <div>
    <el-card class="box-card">
      <div class="headTips">{{ $t('alarm.main') }}</div>
      <el-form
          :inline="true"
          :model="alarminfo"
          class="demo-form-inline"
          ref="searchForm"
          :label="$t('alarm.main')"
          label-width="120px"
          label-position="left"
      >
        <el-form-item :label="$t('alarm.notice')" prop="notice">
          <el-switch
              v-model="alarminfo.notice"
              :active-text="$t('alarm.open')"
              inline-prompt
              :inactive-text="$t('alarm.close')"
              :active-value="1"
              :inactive-value="2"
          ></el-switch>
        </el-form-item>
        <el-form-item :label="$t('alarm.sound')" prop="voice" v-show="alarminfo.notice == 1">
          <el-switch
              v-model="alarminfo.voice"
              :active-text="$t('alarm.open')"
              inline-prompt
              :inactive-text="$t('alarm.close')"
              :active-value="1"
              :inactive-value="2"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div v-show="alarminfo.notice == 1">
        <div class="headTips">{{ $t('alarm.security') }}</div>
        <el-form
            :inline="true"
            :model="alarminfo"
            class="demo-form-inline"
            ref="searchForm"
            :label="$t('alarm.main')"
            label-width="160px"
            label-position="left"
        >
          <el-form-item :label="$t('alarm.enter_fence')" prop="enter_notice">
            <el-switch
                v-model="alarminfo.enter_notice"
                :active-text="$t('alarm.open')"
                inline-prompt
                :inactive-text="$t('alarm.close')"
                :active-value="1"
                :inactive-value="2"
            ></el-switch>
            <img
                :src="require('@/assets/img/' + ((alarminfo.enter_voice == 1) ? 'open.png':'close.png'))"
                v-show="alarminfo.voice == 1"
                @click="voiceChange('enter_voice')"
                alt
            />
          </el-form-item>
          <el-form-item :label="$t('alarm.leaving_fence')" prop="leave_notice">
            <el-switch
                v-model="alarminfo.leave_notice"
                :active-text="$t('alarm.open')"
                inline-prompt
                :inactive-text="$t('alarm.close')"
                :active-value="1"
                :inactive-value="2"
            ></el-switch>
            <img
                :src="require('@/assets/img/' + ((alarminfo.leave_voice == 1) ? 'open.png':'close.png'))"
                v-show="alarminfo.voice == 1"
                @click="voiceChange('leave_voice')"
                alt
            />
          </el-form-item>
          <el-form-item :label="$t('alarm.sos_alarm')" prop="sos_notice">
            <el-switch
                v-model="alarminfo.sos_notice"
                :active-text="$t('alarm.open')"
                inline-prompt
                :inactive-text="$t('alarm.close')"
                :active-value="1"
                :inactive-value="2"
            ></el-switch>
            <img
                :src="require('@/assets/img/' + ((alarminfo.sos_voice == 1) ? 'open.png':'close.png'))"
                v-show="alarminfo.voice == 1"
                @click="voiceChange('sos_voice')"
                alt
            />
          </el-form-item>
        </el-form>
        <div class="headTips">{{ $t('alarm.device') }}</div>
        <el-form
            :inline="true"
            :model="alarminfo"
            class="demo-form-inline"
            ref="searchForm"
            label-width="160px"
            label-position="left"
        >
          <el-form-item :label="$t('alarm.low_battery')" prop="volt_notice">
            <el-switch
                v-model="alarminfo.volt_notice"
                :active-text="$t('alarm.open')"
                inline-prompt
                :inactive-text="$t('alarm.close')"
                :active-value="1"
                :inactive-value="2"
            ></el-switch>
            <img
                :src="require('@/assets/img/' + ((alarminfo.volt_voice == 1) ? 'open.png':'close.png'))"
                v-show="alarminfo.voice == 1"
                @click="voiceChange('volt_voice')"
                alt
            />
          </el-form-item>
          <el-form-item :label="$t('alarm.removing_alarm')" prop="take_off_notice">
            <el-switch
                v-model="alarminfo.take_off_notice"
                :active-text="$t('alarm.open')"
                inline-prompt
                :inactive-text="$t('alarm.close')"
                :active-value="1"
                :inactive-value="2"
            ></el-switch>
            <img
                :src="require('@/assets/img/' + ((alarminfo.take_off_voice == 1) ? 'open.png':'close.png'))"
                v-show="alarminfo.voice == 1"
                @click="voiceChange('take_off_voice')"
                alt
            />
          </el-form-item>
        </el-form>
        <div class="headTips">{{ $t('alarm.health') }}</div>
        <el-form
            :inline="true"
            :model="alarminfo"
            class="demo-form-inline"
            ref="searchForm"
            label-width="160px"
            label-position="left"
        >
          <el-form-item :label="$t('alarm.low_temp')" prop="temp_down_notice">
            <el-switch
                v-model="alarminfo.temp_down_notice"
                :active-text="$t('alarm.open')"
                inline-prompt
                :inactive-text="$t('alarm.close')"
                :active-value="1"
                :inactive-value="2"
            ></el-switch>
            <img
                :src="require('@/assets/img/' + ((alarminfo.temp_down_voice == 1) ? 'open.png':'close.png'))"
                v-show="alarminfo.voice == 1"
                @click="voiceChange('temp_down_voice')"
                alt
            />
          </el-form-item>
          <el-form-item :label="$t('alarm.high_temp')" prop="temp_up_notice">
            <el-switch
                v-model="alarminfo.temp_up_notice"
                :active-text="$t('alarm.open')"
                inline-prompt
                :inactive-text="$t('alarm.close')"
                :active-value="1"
                :inactive-value="2"
            ></el-switch>
            <img
                :src="require('@/assets/img/' + ((alarminfo.temp_up_voice == 1) ? 'open.png':'close.png'))"
                v-show="alarminfo.voice == 1"
                @click="voiceChange('temp_up_voice')"
                alt
            />
          </el-form-item>
          <el-form-item :label="$t('alarm.low_heart')" prop="heart_down_notice">
            <el-switch
                v-model="alarminfo.heart_down_notice"
                :active-text="$t('alarm.open')"
                inline-prompt
                :inactive-text="$t('alarm.close')"
                :active-value="1"
                :inactive-value="2"
            ></el-switch>
            <img
                :src="require('@/assets/img/' + ((alarminfo.heart_down_voice  == 1) ? 'open.png':'close.png'))"
                v-show="alarminfo.voice == 1"
                @click="voiceChange('heart_down_voice')"
                alt
            />
          </el-form-item>
          <el-form-item :label="$t('alarm.high_heart')" prop="heart_up_notice">
            <el-switch
                v-model="alarminfo.heart_up_notice"
                :active-text="$t('alarm.open')"
                inline-prompt
                :inactive-text="$t('alarm.close')"
                :active-value="1"
                :inactive-value="2"
            ></el-switch>
            <img
                :src="require('@/assets/img/' + ((alarminfo.heart_up_voice == 1) ? 'open.png':'close.png'))"
                v-show="alarminfo.voice == 1"
                @click="voiceChange('heart_up_voice')"
                alt
            />
          </el-form-item>
          <el-form-item :label="$t('alarm.high_systolic')" prop="diastolic_down_notice">
            <el-switch
                v-model="alarminfo.diastolic_down_notice"
                :active-text="$t('alarm.open')"
                inline-prompt
                :inactive-text="$t('alarm.close')"
                :active-value="1"
                :inactive-value="2"
            ></el-switch>
            <img
                :src="require('@/assets/img/' + ((alarminfo.diastolic_down_voice == 1) ? 'open.png':'close.png'))"
                v-show="alarminfo.voice == 1"
                @click="voiceChange('diastolic_down_voice')"
                alt
            />
          </el-form-item>
          <el-form-item :label="$t('alarm.low_diastolic')" prop="diastolic_up_notice">
            <el-switch
                v-model="alarminfo.diastolic_up_notice"
                :active-text="$t('alarm.open')"
                inline-prompt
                :inactive-text="$t('alarm.close')"
                :active-value="1"
                :inactive-value="2"
            ></el-switch>
            <img
                :src="require('@/assets/img/' + ((alarminfo.diastolic_up_voice == 1) ? 'open.png':'close.png'))"
                v-show="alarminfo.voice == 1"
                @click="voiceChange('diastolic_up_voice')"
                alt
            />
          </el-form-item>
          <el-form-item :label="$t('alarm.high_systolic')" prop="systolic_down_notice">
            <el-switch
                v-model="alarminfo.systolic_down_notice"
                :active-text="$t('alarm.open')"
                inline-prompt
                :inactive-text="$t('alarm.close')"
                :active-value="1"
                :inactive-value="2"
            ></el-switch>
            <img
                :src="require('@/assets/img/' + ((alarminfo.systolic_down_voice == 1) ? 'open.png':'close.png'))"
                v-show="alarminfo.voice == 1"
                @click="voiceChange('systolic_down_voice')"
                alt
            />
          </el-form-item>
          <el-form-item :label="$t('alarm.low_systolic')" prop="systolic_up_notice">
            <el-switch
                v-model="alarminfo.systolic_up_notice"
                :active-text="$t('alarm.open')"
                inline-prompt
                :inactive-text="$t('alarm.close')"
                :active-value="1"
                :inactive-value="2"
            ></el-switch>
            <img
                :src="require('@/assets/img/' + ((alarminfo.systolic_up_voice == 1) ? 'open.png':'close.png'))"
                v-show="alarminfo.voice == 1"
                @click="voiceChange('systolic_up_voice')"
                alt
            />
          </el-form-item>
        </el-form>
      </div>
      <el-button type="primary" @click="submitSet">{{ $t('alarm.upload') }}</el-button>
    </el-card>
  </div>
</template>
<script>
import alarm from "@/model/alarm";

export default {
  name: "alarm",
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      pageTotal: "",
      formInline: {
        value3: 1
      },
      alarminfo: {},
      mapPop: false,
      alarmData: []
    };
  },
  async mounted() {
    await this.alarmSetList();
  },
  methods: {
    async alarmSetList() {
      const ret = await alarm.alarmSet();
      if (ret.code === 200) {
        this.alarminfo = ret.data;
      }
    },
    async submitSet() {
      this.$confirm(this.$t('alarm.confirm'), this.$t('device.tip'), {
        confirmButtonText: this.$t('device.confirm'),
        cancelButtonText: this.$t('device.cancel'),
        type: "warning"
      })
          .then(async () => {
            const ret = await alarm.operSet(this.alarminfo);
            if (ret.code === 200) {
              this.alarmSetList();
              this.$message.success(ret.message);
            } else {
              this.$message.error(ret.message);
            }
          })
          .catch(() => {
          });
    },
    voiceChange(prop) {
      this.alarminfo[prop] = this.alarminfo[prop] == 1 ? 2 : 1;
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}

.el-form--inline {
  width: 100%;
}

.el-form--inline .el-form-item {
  width: 22%;
}

.headTips {
  margin-top: 30px;
  font-size: 15px;
}

.el-form /deep/ .el-form-item__content {
  display: flex;
  align-items: center;
}

.el-form img {
  width: 28px;
  height: 27px;
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
}
</style>
